import React from "react"
import { Link } from "gatsby"

import ViolinCat from '../assets/violin-cat.jpg';
import ArrowRight from '../assets/arrow-right.svg';

import Layout from "../components/layout"
import '../components/home.scss';
import '../components/projects.scss';

export default function Home() {
  return (
    <Layout>
      <section className="section-no-margin">
        <div className="row">
          <div className="align-center">
            <img className="col-8 margin-btm-lg WI-header col-100" src={ViolinCat} alt="Cat playing the violin" />
            <div className="align-left">
              <h1 className="header--medium margin-btm-xxs">Cat with Violin</h1>
              <div className="flex flex-break-sm">
                <div className="col-7 divider-coral col-100 mobile-margin-btm-xs">
                  <p>This illustration was simply a personal project. An image that popped into my head as I was playing my own violin.</p>
                </div>
                <div className="flex flex-col col-5 col-100">
                  <div className="margin-btm-xxs">
                    <label className="header-label">Role:</label>
                    <span className="text-sm">Illustrator</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-fullwidth">
        <div className="footer-projects footer-robots">
          <div className="wrapper-project-footer flex-center column">
            <div className="section-internal flex flex-row space-between">
              <div>
                <span className="next-previous">Next Project</span>
                <Link to="/robot-heads/">
                  <h3 className="header--medium">Sunlight Foundation - Robot Heads</h3>
                </Link>
                <span className="description">Illustration</span>
              </div>
              <Link to="/robot-heads/">
                <img className="next-arrow" src={ArrowRight} alt="arrow" />
              </Link>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
